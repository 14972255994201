[role=tablist]{
    margin-top: 55px;
    background: white;
}

.tab-content{
    margin-top: 115px;
    margin-bottom: 65px;
}

.orderRow:hover {
    cursor: pointer;
}